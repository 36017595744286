/** @jsx jsx */
import { jsx } from "theme-ui"

import Image from "./Image"

import { motion } from "framer-motion"

export default function Gallery({ images }) {
  const animation = {
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
    hidden: {
      opacity: 0,
    },
  }
  const imageAnimation = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  }
  return (
    <motion.div
      initial={"hidden"}
      animate={"visible"}
      variants={animation}
      sx={{
        display: "grid",
        gridTemplateColumns: [
          "repeat(1, 1fr)",
          "repeat(2, 1fr)",
          "repeat(4, 1fr)",
        ],
        gap: "2px",
      }}
    >
      {images.map(image => {
        return (
          <Image key={image.id} imageData={image} animation={imageAnimation} />
        )
      })}
    </motion.div>
  )
}
