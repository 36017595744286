import PageStructure from "../../components/PageStructure"

import React from "react"

import { graphql } from "gatsby"
import Gallery from "../../components/Homepage/Art/Gallery"
import { H2 } from "../../components/Homepage/MarkdownTemplates/Blocks/Headings"
import Spacing from "../../components/Atomic/Spacing"

export default function art({ data, location }) {
  const images = data.allContentfulAsset.edges
    .filter(edge => edge?.node?.file?.contentType === "image/png")
    .map(edge => {
      return {
        description: edge.node.description,
        id: edge.node.id,
        ...edge.node.file,
      }
    })
  const gifs = data.allContentfulAsset.edges
    .filter(edge => edge?.node?.file?.contentType === "image/gif")
    .map(edge => {
      return {
        description: edge.node.description,
        id: edge.node.id,
        ...edge.node.file,
      }
    })
  return (
    <PageStructure
      pageTitle={`Art`}
      metaContent={`A gallery of my 3D and 2D art`}
      location={location}
    >
      <Spacing py={`sm`}>
        <H2>Pictures</H2>
        <Gallery images={images} />
      </Spacing>
      <Spacing py={`sm`}>
        <H2>Gifs</H2>
        <Gallery images={gifs} />
      </Spacing>
    </PageStructure>
  )
}

export const query = graphql`
  {
    allContentfulAsset {
      edges {
        node {
          id
          file {
            url
            fileName
            contentType
          }
          description
        }
      }
    }
  }
`
