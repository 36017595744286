import Text from "../../../Atomic/Text"

import React from "react"

export function H1({ children }) {
  return (
    <Text
      as={`h1`}
      sx={{
        color: "white",
        paddingY: "sm",
        fontSize: ["h3", "h2", null, "h1"],
      }}
    >
      {children}
    </Text>
  )
}
export function H2({ children }) {
  return (
    <Text
      as={`h2`}
      sx={{
        color: "white",
        paddingY: "sm",
        fontSize: ["xl", "h3", null, "h2"],
      }}
    >
      {children}
    </Text>
  )
}
export function H3({ children }) {
  return (
    <Text
      as={`h3`}
      sx={{
        color: "white",
        paddingY: "sm",
        fontSize: ["lg", "xl", null, "h3"],
      }}
    >
      {children}
    </Text>
  )
}
export function H4({ children }) {
  return (
    <Text
      as={`h4`}
      sx={{
        color: "white",
        paddingY: "sm",
        fontSize: ["md", "lg", null, "xl"],
      }}
    >
      {children}
    </Text>
  )
}
