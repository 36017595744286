/** @jsx jsx */
import { jsx } from "theme-ui"
import Box from "../../Atomic/Box"

import { motion } from "framer-motion"

import {} from 'gatsby';

export default function Image({ imageData, animation }) {
  return (
    <motion.div variants={animation}>
      <Box as={`img`} src={imageData.url} alt={imageData.description} />
    </motion.div>
  )
}
